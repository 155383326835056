import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import {
  getListOfWholesalerCoupon,
  downloadallCoupon,
  getListOfDateSearchCoupon
} from "../../API/Wholesalerapi";
import ls from "local-storage";
import analytics from "../../Assets/images/analytics.png";
import sort from "../../Assets/images/sort.svg";
import Header from "../../Components/Wholesaler/layout/header.js";
import { DateFormatter } from "../../Components/Helper";
import edit_pencil from "../../Assets/images/edit_pencil.png";
import digitalCoupons from "../../Assets/images/coupons.svg";
import search from "../../Assets/images/search.png";
import cross from "../../Assets/images/cross.png";
import expire from "../../Assets/images/expire.png";
import copy from "../../Assets/images/copy-32.png";
import { renderTooltip } from "../../Components/Helper";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import ClipLoader from "react-spinners/ClipLoader";
import CouponExpire from "../../Components/Common/ConfirmationModal/CouponExpire";
import $ from "jquery";
import * as CONFIG from "../../Components/_config";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Button, Form, Spinner } from "react-bootstrap";
import { getBrands } from "../../API/DashBoard";
import { Link } from "react-router-dom";
import Paginate from "./Paginate";
import { async } from "q";
import localStorage from "local-storage";

const List = () => {
  var page;
  const [tableData, setTableData] = useState([]);
  const [wholesalerData, setWholeSalerData] = useState([]);

  const [showContainer, setShowContainer] = useState(false);

  const handleClick = () => {
    setShowContainer(!showContainer);
  };

  const [filedata, setFiledata] = useState([]);
  const [downloadcouponloader, setDownloadcouponloader] = useState(true);
  const [couponloader, setCouponLoader] = useState("true");
  const [searchData, setSearchData] = useState("");
  // const [updated, setUpdated] = useState(searchData)

  const handleChange = (e) => {
    setSearchData(e.target.value);
    //console.log("search", searchData)
  };

  // const handleClick = () => {
  //   setUpdated(searchData)
  // }

  const Loader = () => <div id="cover-spin"></div>;

  const [pageloader, setPageloader] = useState(false);
  const [couponid, setCouponid] = useState("");

  const [totalRecordsLength, setTotalRecordsLength] = useState();
  const [sortItem, setSortItem] = useState("");

  const toggleLoader = () => {
    setPageloader(!pageloader);
  };

  const expireCoupon = () => {
    $("#couponExpire").css("display", "none");
    $(".modal-backdrop").first().css("display", "none");

    toggleLoader();

    var config = {
      method: "get",
      url: CONFIG.API_URL + `coupon/expire/${couponid}`,
      headers: CONFIG.config_head.headers
    };

    axios(config)
      .then((response) => {
        if (response.status === 204) {
          toggleLoader();
          toast.success("Expired Succesfully");
          setTimeout(() => window.location.reload(), 2500);
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 502) {
            toast.error("Bad Request!");
          } else if (error.response.status === 400) {
            toggleLoader();
            toast.error(error.response.data.Message);
          } else {
            toggleLoader();
            toast.error(error.response.data.Message);
          }
        }
      });
  };

  // const columns = [
  //   {
  //     name: "Coupon Title",
  //     // eslint-disable-next-line no-useless-escape
  //     selector: (row) => row.coupon_title.replace(/\\'/g, "'"),
  //     cell: (row) => (
  //       <>
  //         <a
  //           href={`/edit-whcoupon/${row.coupon_guid}`}
  //           className="text-decoration-none"
  //           style={{ textDecoration: "underline" }}
  //         >
  //           {row.coupon_title.replace(/\\'/g, "'")}
  //         </a>
  //       </>
  //     )
  //   },
  //   {
  //     name: "Value",
  //     selector: (row) =>
  //       row.is_discount_percentage === false
  //         ? "$ " + row.reward_amount + " OFF"
  //         : row.reward_amount + " % OFF"
  //   },
  //   {
  //     name: "Start",
  //     selector: (row) =>
  //       DateFormatter(new Date(row.coupon_start_date.split("T")[0]))
  //   },
  //   {
  //     name: "End",
  //     selector: (row) =>
  //       DateFormatter(new Date(row.coupon_end_date.split("T")[0]))
  //   },
  //   {
  //     name: "ShutOff",
  //     selector: (row) =>
  //       DateFormatter(new Date(row.coupon_shutoff_date.split("T")[0]))
  //   },

  //   {
  //     name: "Clips",
  //     selector: (row) => row.clip_count
  //   },
  //   {
  //     name: "Redemptions",
  //     selector: (row) => row.redemption_count
  //   },
  //   {
  //     name: "Copy/Edit/Expire",
  //     selector: (row) => row.category_name,
  //     cell: (row) => (
  //       <>
  //         <a
  //           href={`/copy-whcoupon/${row.coupon_guid}`}
  //           className="text-decoration-none"
  //         >
  //           <OverlayTrigger
  //             placement="top"
  //             delay={{ show: 250, hide: 400 }}
  //             overlay={renderTooltip("Copy")}
  //           >
  //             <img
  //               src={copy}
  //               height={"23px"}
  //               width={"22px"}
  //               style={{ marginRight: "12px" }}
  //               alt={row.category_name}
  //             />
  //           </OverlayTrigger>
  //         </a>

  //         <a
  //           href={`/edit-whcoupon/${row.coupon_guid}`}
  //           className="text-decoration-none"
  //         >
  //           <OverlayTrigger
  //             placement="top"
  //             delay={{ show: 250, hide: 400 }}
  //             overlay={renderTooltip("Edit")}
  //           >
  //             <img
  //               src={edit_pencil}
  //               height={"23px"}
  //               width={"22px"}
  //               style={{ marginRight: "12px" }}
  //               alt={row.category_name}
  //             />
  //           </OverlayTrigger>
  //         </a>

  //         {row.is_expired ? (
  //           <OverlayTrigger
  //             placement="top"
  //             delay={{ show: 250, hide: 400 }}
  //             overlay={renderTooltip("Alredy Expired")}
  //           >
  //             <img src={expire} height={"26px"} width={"25px"} alt={`expire`} />
  //           </OverlayTrigger>
  //         ) : (
  //           <a
  //             className="text-decoration-none"
  //             data-bs-toggle="modal"
  //             data-bs-target="#couponExpire"
  //             onClick={() => {
  //               setCouponid(row.coupon_guid);
  //             }}
  //             href={(e) => e.preventDefault()}
  //           >
  //             <OverlayTrigger
  //               placement="top"
  //               delay={{ show: 250, hide: 400 }}
  //               overlay={renderTooltip("Expire")}
  //             >
  //               <img
  //                 src={expire}
  //                 height={"26px"}
  //                 width={"25px"}
  //                 alt={`expire`}
  //               />
  //             </OverlayTrigger>
  //           </a>
  //         )}
  //       </>
  //     )
  //   },
  //   {
  //     name: "Status",
  //     selector: (row) => row.is_expired,
  //     cell: (row) =>
  //       row.is_expired ? (
  //         <button
  //           type="button"
  //           className="btn btn-outline-danger"
  //           style={{ pointerEvents: "none", opacity: "1" }}
  //         >
  //           Expired
  //         </button>
  //       ) : (
  //         <button
  //           type="button"
  //           className="btn btn-outline-success"
  //           style={{ pointerEvents: "none", opacity: "0.8" }}
  //         >
  //           Active
  //         </button>
  //       )
  //   },
  //   {
  //     name: "Analysis",
  //     selector: (row) => row.redemption_count,
  //     cell: (row) => (
  //       <a
  //         href={`/whcoupon-details/${row.coupon_guid}`}
  //         className="text-decoration-none"
  //       >
  //         <OverlayTrigger
  //           placement="top"
  //           delay={{ show: 250, hide: 400 }}
  //           overlay={renderTooltip("Analysis")}
  //         >
  //           <img
  //             src={analytics}
  //             height={"26px"}
  //             width={"25px"}
  //             alt={`analytics`}
  //           />
  //         </OverlayTrigger>
  //       </a>
  //     )
  //   }
  // ];

  const guid = ls.get("brands") ? ls.get("brands")[0].guid : "";

  useEffect(() => {
    const getCoupons = async () => {
      const response = await getListOfWholesalerCoupon(guid);
      if (response.status === 200) {
        setTableData(response.data.Message.entries);
        setTotalRecordsLength(response?.data?.Message?.totalcount);
        setCouponLoader(!couponloader);
      } else {
        setTableData([]);
      }
    };
    getCoupons();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const getWholeslaler = async () => {
      const response = await getBrands(guid);
      if (Object.keys(response.Message).length > 1) {
        setWholeSalerData(response.Message);
        ls.set("wholesalerId", response.Message.wholesalers[0]?.WholesalerId);
      } else {
        setWholeSalerData([]);
      }
    };
    getWholeslaler();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const couponownload = () => {
    if (Object.keys(wholesalerData).length > 1) {
      downloadallCoupon(
        wholesalerData.wholesalers[0].WholesalerNumber,
        guid
      ).then((response) => {
        if (response.status === 200) {
          setFiledata(response.data.Message);
          setDownloadcouponloader(!downloadcouponloader);

          $("#mybtn").trigger("click");
        } else {
          toast.error(response.data.Message);
        }
      });
    } else {
      toast.error("No Coupon Present");
    }
  };
  const [name, setName] = useState("");
  const [remountComponent, setRemountComponent] = useState(0);
  const pageCount = Math.ceil(totalRecordsLength / 10);
  const [sortdesc, setSortdesc] = useState(0);
  const targetValue = (e) => {
    return e.target.value;
  };
  // console.log("length", targetValue.length >= 1);

  const handlePageClick = (event) => {
    //console.log(event.selected + 1);
    page = event.selected;
    //const page = event.selected;
    // setItemOffset(newOffset);
    // if(sortdesc==1){
    //   dispatch(ruleListData(limitPerPage, newOffset,sortItem,0));
    // }else{
    //   dispatch(ruleListData(limitPerPage, newOffset,sortItem,1));
    // }
    let sortvalue;
    if (sortdesc == 1) {
      sortvalue = 0;
    } else {
      sortvalue = 1;
    }
    const getCouponsPaginate = async () => {
      const response = await getListOfWholesalerCoupon(
        guid,
        event.selected + 1,
        name,
        sortvalue,
        searchData,
        startDateSearch,
        startDatetoSearch,
        endDateSearch,
        endDateToSearch
      );
      if (response.status === 200) {
        setTableData(response.data.Message.entries);
        setCouponLoader(false);
      } else {
        setTableData([]);
      }
    };
    getCouponsPaginate();
    setCouponLoader(true);
  };

  const sortingData = async (e, name) => {
    setCouponLoader(true);
    setName(name);
    const response = await getListOfWholesalerCoupon(
      guid,
      e.selected + 1,
      name,
      sortdesc,
      searchData,
      startDateSearch,
      startDatetoSearch,
      endDateSearch,
      endDateToSearch
    );
    sortdesc == 1 ? setSortdesc(0) : setSortdesc(1);
    if (response.status === 200) {
      setTableData(response.data.Message.entries);
      setCouponLoader(false);
    } else {
      setTableData([]);
    }
  };

  const handleSearchClose = async () => {
    setSearchData("");
    let sortvalue;
    if (sortdesc == 1) {
      sortvalue = 0;
    } else {
      sortvalue = 1;
    }
    setCouponLoader(true);
    const response = await getListOfWholesalerCoupon(
      guid,
      1,
      name,
      sortvalue,
      "",
      startDateSearch,
      startDatetoSearch,
      endDateSearch,
      endDateToSearch
    );
    if (response.status === 200) {
      setTableData(response.data.Message.entries);
      setTotalRecordsLength(response?.data?.Message?.totalcount);
      setCouponLoader(false);
    } else {
      setTableData([]);
    }
  };

  const clearDates = async () => {
    setEndDateSearch("");
    setEndDateToSearch("");
    setStartDateSearch("");
    setStartDatetoSearch("");
    let sortvalue;
    if (sortdesc == 1) {
      sortvalue = 0;
    } else {
      sortvalue = 1;
    }
    setCouponLoader(true);
    const response = await getListOfWholesalerCoupon(
      guid,
      1,
      name,
      sortvalue,
      searchData
    );
    if (response.status === 200) {
      setTableData(response.data.Message.entries);
      setTotalRecordsLength(response?.data?.Message?.totalcount);
      setCouponLoader(false);
    } else {
      setTableData([]);
    }
  }

  const [showRemainingBrands, setShowRemainingBrands] = useState(false);
  const handleSearch = async () => {
    if (
      (startDateSearch != "" && startDatetoSearch === "") ||
      (startDateSearch === "" && startDatetoSearch != "")
    ) {
      toast.error("Please Select Start Dates.");
      return;
    } else if (
      (endDateSearch != "" && endDateToSearch === "") ||
      (endDateSearch === "" && endDateToSearch != "")
    ) {
      toast.error("Please Select End Dates.");
      return;
    }
    else{
      let sortvalue;
      if (sortdesc == 1) {
        sortvalue = 0;
      } else {
        sortvalue = 1;
      }

      setCouponLoader(true);
      const response = await getListOfWholesalerCoupon(
        guid,
        1,
        name,
        sortvalue,
        searchData,
        startDateSearch,
        startDatetoSearch,
        endDateSearch,
        endDateToSearch
      );
      if (response.status === 200) {
        setTableData(response.data.Message.entries);
        setTotalRecordsLength(response?.data?.Message?.totalcount);
        setCouponLoader(false);
      } else {
        setTableData([]);
      }
    }
  };
  const [startDateSearch, setStartDateSearch] = useState("");
  const [startDatetoSearch, setStartDatetoSearch] = useState("");
  const [endDateSearch, setEndDateSearch] = useState("");
  const [endDateToSearch, setEndDateToSearch] = useState("");

  const handleSearchDate = async () => {
    setCouponLoader(true);
    const response = await getListOfDateSearchCoupon(
      guid,
      1,
      startDateSearch,
      startDatetoSearch,
      endDateSearch,
      endDateToSearch
    );
    console.log(response);
    if (response.status === 200) {
      setTableData(response.data.Message.entries);
      setTotalRecordsLength(response?.data?.Message?.totalcount);
      setCouponLoader(false);
    } else {
      setTableData([]);
    }
  };

  return (
    <React.Fragment>
      <Header />

      {pageloader ? <Loader /> : null}

      <div
        className="ContentBlock pt-40 pb-24 Main_Block"
        style={{ padding: "20px" }}
      >
        <a
          href={filedata}
          target="_blank"
          rel="noopener noreferrer"
          download
          style={{ display: "none" }}
        >
          <Button id="mybtn">Download File</Button>
        </a>

        {/* <a href='/add-excel'>
                    <button className='btn Add_User_Button' style={{ float: 'right', margin: '3px' }}>Add Excel</button>
                </a> */}

        <Button
          variant=""
          type="button"
          className="Digital_Brand_Button approve mt-20"
          style={{
            float: "right",
            padding: "10px",
            border: "none",
            margin: "1px"
          }}
          onClick={couponownload}
        >
          Download All Coupons
        </Button>

        <a href="/create-wholesalercoupon">
          <button
            className="btn Add_User_Button mt-20"
            style={{ float: "right", margin: "3px" }}
          >
            Create Coupon
          </button>
        </a>
        {/* <Link
          to="/create-bulkcoupon"
          className="btn Add_User_Button mt-20"
          style={{ float: "right", margin: "3px" }}
        >
          Create Coupon in Bulk
        </Link> */}
        <Link
          to="/create-scan"
          className="btn Add_User_Button mt-20"
          style={{ float: "right", margin: "3px" }}
        >
          Create Scan
        </Link>
        <Link
          to="/retailer-groups"
          className="btn Add_User_Button mt-20"
          style={{ float: "right", margin: "3px" }}
        >
          Retailer Group
        </Link>
        {couponloader ? (
          <div className="Clip_Loader" style={{ minHeight: "280px" }}>
            <ClipLoader color={"rgb(0, 143, 251)"} loading={true} size={50} />
          </div>
        ) : (
          <>
            <div style={{ backgroundColor: "white" }}>
              <div className="search-form">
                <div className="d-flex align-items-center">
                  <img
                    src={digitalCoupons}
                    className="img-fluid ml-15 mt-15"
                    alt="Digial Coupons"
                  />
                  <h5 style={{ fontSize: "18px", marginTop: "15px" }}>
                    Wholesaler Promotions
                  </h5>
                </div>
                <div>
                  <form className="example input-align">
                    <input
                      type="text"
                      placeholder="Search.."
                      name="search"
                      onChange={handleChange}
                      value={searchData}
                      className="search-input"
                      onKeyDown={(e) => {
                        if (e.keyCode === 13) {
                          handleSearch();
                          e.preventDefault();
                        }
                      }}
                    />

                    <img
                      src={cross}
                      className="cross-img"
                      onClick={(e) => {
                        handleSearchClose();
                        e.preventDefault();
                      }}
                    />
                    <img
                      src={search}
                      className="search-img"
                      onClick={(e) => {
                        handleSearch();
                        e.preventDefault();
                      }}
                    />
                    {/* <button
                      type="submit"
                       onKeyDown={(e) =>{
                         if(e.keyCode === 13){
                         handleSearch();
                          e.preventDefault();

                          }
                      }}
                      onClick={(e) => {
                        //handleSearch();
                        e.preventDefault();
                      }}
                      className="search-btn"
                    >
                      <i
                        className="fa fa-search"
                        onClick={(e) => {
                          handleSearch();
                          e.preventDefault();
                        }}
                      ></i>
                    </button> */}
                  </form>
                </div>
              </div>

              <div
                className="Steps_Header d-flex align-items-center justify-content-between mt-15"
                style={{ height: "40px" }}
              >
                <h5>Search by Dates </h5>
                <div className="Search_Box">
                  <input
                    type="text"
                    className="form-control Search_Toggle"
                    placeholder="Search"
                    aria-label="Search"
                    aria-describedby="search-addon"
                  />
                  {showContainer ? (
                    <span className="search_card_rotate" onClick={handleClick}>
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M10 0 L20 20 L0 20 Z" fill="white" />
                      </svg>
                    </span>
                  ) : (
                    <span className="search_card" onClick={handleClick}>
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M10 0 L20 20 L0 20 Z" fill="white" />
                      </svg>
                    </span>
                  )}
                </div>
              </div>
              {showContainer && (
                <div className="Steps_Header  mt-15 d-flex align-items-center justify-content-between" style={{height:"40px"}}>
                  <div className="d-flex">
                      <h6
                        style={{
                          textAlign: "center",
                          color: "white",
                          marginTop:"5px",
                          marginRight:"15px",
                          fontWeight:"bold"
                        }}
                      >
                        Start Date
                      </h6>
                    <div className="d-flex">
                      <div className="d-flex mr-10">
                        <h6 className="mr-6 mt-5" style={{ color: "white" }}>
                          between
                        </h6>
                        <input
                          type="date"
                          className="search_date"
                          value={startDateSearch.split("T")[0]}
                          onChange={(e) =>
                            setStartDateSearch(
                              new Date(e.target.value).toISOString()
                            )
                          }
                        />
                      </div>
                      <div className="d-flex">
                        <h6 className="mr-6 mt-5" style={{ color: "white" }}>
                          and
                        </h6>
                        <input
                          type="date"
                          className="search_date"
                          value={startDatetoSearch.split("T")[0]}
                          onChange={(e) =>
                            setStartDatetoSearch(
                              new Date(e.target.value).toISOString()
                            )
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div className="d-flex">
                      <h6
                        style={{
                          textAlign: "center",
                          color: "white",
                          marginTop:"5px",
                          marginRight:"15px",
                          fontWeight:"bold"
                        }}
                      >
                        End Date
                      </h6>
                    <div className="d-flex">
                      <div className="d-flex mr-10">
                        <h6 className="mr-6 mt-5" style={{ color: "white" }}>
                          between
                        </h6>
                        <input
                          type="date"
                          className="search_date"
                          value={endDateSearch.split("T")[0]}
                          onChange={(e) =>
                            setEndDateSearch(
                              new Date(e.target.value).toISOString()
                            )
                          }
                        />
                      </div>
                      <div className="d-flex">
                        <h6 className="mr-6 mt-5" style={{ color: "white" }}>
                          and
                        </h6>
                        <input
                          type="date"
                          className="search_date"
                          value={endDateToSearch.split("T")[0]}
                          onChange={(e) =>
                            setEndDateToSearch(
                              new Date(e.target.value).toISOString()
                            )
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div >
                    <button className="search_button" onClick={handleSearch}>
                      Search
                    </button>
                  </div>
                  <div >
                    <button className="search_clear" onClick={clearDates}>
                      Clear
                    </button>
                  </div>
                </div>
              )}
              <div className="table-responsive Coupons_Table">
                <table className="table mt-25">
                  <thead>
                    <tr>
                      <th>
                        Promotion
                        <img
                          src={sort}
                          alt="Sorting"
                          className="img-fluid ml-15"
                          onClick={(e) => sortingData(e, "coupon_title")}
                        />
                      </th>
                      <th style={{ textAlign: "center" }}>
                        Value
                        <img
                          src={sort}
                          alt="Sorting"
                          className="img-fluid ml-15"
                          onClick={(e) => sortingData(e, "reward_amount")}
                        />
                      </th>
                      <th>
                        Start
                        <img
                          src={sort}
                          alt="Sorting"
                          className="img-fluid ml-15"
                          onClick={(e) => sortingData(e, "coupon_start_date")}
                        />
                      </th>
                      <th>
                        End
                        <img
                          src={sort}
                          alt="Sorting"
                          className="img-fluid ml-15"
                          onClick={(e) => sortingData(e, "coupon_end_date")}
                        />
                      </th>
                      <th>Brand(s)</th>
                      <th>
                        Clips
                        <img
                          src={sort}
                          alt="Sorting"
                          className="img-fluid ml-15"
                          onClick={(e) => sortingData(e, "clip_count")}
                        />
                      </th>
                      <th>
                        Redemptions
                        <img
                          src={sort}
                          alt="Sorting"
                          className="img-fluid ml-15"
                          onClick={(e) => sortingData(e, "redemption_count")}
                        />
                      </th>
                      <th>Copy/Edit/Expire</th>
                      <th>Status</th>
                      <th>Analysis</th>
                    </tr>
                  </thead>
                  <tbody>
                    {tableData.length < 1 ? (
                      <label style={{ textAlign: "center", fontWeight: "700" }}>
                        No Coupon to display
                      </label>
                    ) : (
                      tableData.map((each, i) => (
                        <tr key={i}>
                          {/* {console.log(tableData)} */}
                          <th style={{ fontSize: "12px" }}>
                            <a
                              href={`/edit-whcoupon/${each.coupon_guid}`}
                              className="text-decoration-none"
                              style={{ textDecoration: "underline" }}
                            >
                              {each.coupon_title}
                            </a>
                          </th>
                          <th style={{ fontSize: "12px", textAlign: "center" }}>
                            {each.is_discount_percentage === false
                              ? "$ " + each.reward_amount + " OFF"
                              : each.reward_amount + " % OFF"}
                          </th>
                          <th style={{ fontSize: "12px", textAlign: "center" }}>
                            {DateFormatter(
                              new Date(each.coupon_start_date.split("T")[0])
                            )}
                          </th>
                          <th style={{ fontSize: "12px", textAlign: "center" }}>
                            {DateFormatter(
                              new Date(each.coupon_end_date.split("T")[0])
                            )}
                          </th>
                          {/* {console.log("brands", each.associated_brands)} */}
                          {/* <th style={{ fontSize: "12px", textAlign: "center" }}>
                              {each.associated_brands.map((each) =>
                                <p>{each.brand_name}</p>
                              )}
                            </th> */}

                          <th
                            style={{ fontSize: "12px", textAlign: "center" }}
                            onMouseOver={(event) =>
                            (event.target.title = each.associated_brands
                              .map((brand) => brand.brand_name)
                              .join(", "))
                            }
                            onMouseLeave={(event) => (event.target.title = "")}
                          >
                            {each.associated_brands.slice(0, 2).map((each) => (
                              <p>{each.brand_name}</p>
                            ))}
                            {each.associated_brands.length > 2 && (
                              <>
                                {!showRemainingBrands && <p>...</p>}
                                <div
                                  style={{
                                    display: showRemainingBrands
                                      ? "block"
                                      : "none"
                                  }}
                                >
                                  {each.associated_brands
                                    .slice(2)
                                    .map((each) => (
                                      <p>{each.brand_name}</p>
                                    ))}
                                </div>
                              </>
                            )}
                          </th>

                          <th style={{ fontSize: "13px", textAlign: "center" }}>
                            {each.clip_count}
                          </th>
                          <th style={{ fontSize: "12px", textAlign: "center" }}>
                            {each.redemption_count}
                          </th>
                          <th style={{ textAlign: "center" }}>
                            <a
                              href={`/copy-whcoupon/${each.coupon_guid}`}
                              className="text-decoration-none"
                            >
                              <OverlayTrigger
                                placement="top"
                                delay={{ show: 250, hide: 400 }}
                                overlay={renderTooltip("Copy")}
                              >
                                <img
                                  src={copy}
                                  height={"23px"}
                                  width={"22px"}
                                  style={{ marginRight: "12px" }}
                                  alt={each.category_name}
                                />
                              </OverlayTrigger>
                            </a>

                            <a
                              href={`/edit-whcoupon/${each.coupon_guid}`}
                              className="text-decoration-none"
                            >
                              <OverlayTrigger
                                placement="top"
                                delay={{ show: 250, hide: 400 }}
                                overlay={renderTooltip("Edit")}
                              >
                                <img
                                  src={edit_pencil}
                                  height={"23px"}
                                  width={"22px"}
                                  style={{ marginRight: "12px" }}
                                  alt={each.category_name}
                                />
                              </OverlayTrigger>
                            </a>

                            {each.is_expired === true ? (
                              <OverlayTrigger
                                placement="top"
                                delay={{ show: 250, hide: 400 }}
                                overlay={renderTooltip("Alredy Expired")}
                              >
                                <img
                                  src={expire}
                                  height={"26px"}
                                  width={"25px"}
                                  alt={`expire`}
                                />
                              </OverlayTrigger>
                            ) : (
                              <a
                                className="text-decoration-none"
                                data-bs-toggle="modal"
                                data-bs-target="#couponExpire"
                                onClick={() => {
                                  setCouponid(each.coupon_guid);
                                }}
                                href={(e) => e.preventDefault()}
                              >
                                <OverlayTrigger
                                  placement="top"
                                  delay={{ show: 250, hide: 400 }}
                                  overlay={renderTooltip("Expire")}
                                >
                                  <img
                                    src={expire}
                                    height={"26px"}
                                    width={"25px"}
                                    alt={`expire`}
                                  />
                                </OverlayTrigger>
                              </a>
                            )}
                          </th>
                          <th style={{ textAlign: "center" }}>
                            {each.is_expired === true ? (
                              <button
                                type="button"
                                className="btn btn-outline-danger"
                                style={{ pointerEvents: "none", opacity: "1" }}
                              >
                                Expired
                              </button>
                            ) : (
                              <button
                                type="button"
                                className="btn btn-outline-success"
                                style={{
                                  pointerEvents: "none",
                                  opacity: "0.8"
                                }}
                              >
                                Active
                              </button>
                            )}
                          </th>
                          <th style={{ textAlign: "center" }}>
                            <a
                              href={`/whcoupon-details/${each.coupon_guid}`}
                              className="text-decoration-none"
                            >
                              <OverlayTrigger
                                placement="top"
                                delay={{ show: 250, hide: 400 }}
                                overlay={renderTooltip("Analysis")}
                              >
                                <img
                                  src={analytics}
                                  height={"26px"}
                                  width={"25px"}
                                  alt={`analytics`}
                                />
                              </OverlayTrigger>
                            </a>
                          </th>
                        </tr>
                      ))
                    )}
                  </tbody>
                </table>
              </div>
            </div>
            {/* <div>
              <DataTable
                title="Wholesaler Coupon"
                columns={columns}
                data={tableData}
              />
            </div> */}
          </>
        )}
        <div>
          <Paginate
            handlePageClick={handlePageClick}
            pageCount={parseInt(pageCount)}
          />
        </div>
      </div>

      <CouponExpire callback={expireCoupon} />
    </React.Fragment>
  );
};
export default List;
