import * as CONFIG from "../Components/_config";
import axios from "axios";
import { toast } from "react-toastify";
import ls from "local-storage";

export const getListOfWholesalerCoupon = (
  guid,
  page,
  sortItem,
  sortdesc,
  search,
  startDateSearch,
  startDatetoSearch,
  endDateSearch,
  endDateToSearch
) => {
  console.log(page);
  let data = JSON.stringify({
    brandId: guid,
    active: 1,
    page: page ? page : 1,
    count: 10,
    sort: sortItem,
    "include-clip": true,
    "include-redemption": true,
    search: search ? search : "",
    "coupon-start-date": startDateSearch ? startDateSearch : "",
    "coupon-start-date-to": startDatetoSearch ? startDatetoSearch : "",
    "coupon-end-date": endDateSearch ? endDateSearch : "",
    "coupon-end-date-to":endDateToSearch?endDateToSearch:"",
    sortdesc: sortdesc
  });

  let config = {
    method: "post",
    url: CONFIG.API_URL + "coupon/search",
    headers: CONFIG.config_head.headers,
    data: data
  };
  return axios(config)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      if (error.response) {
        if (error.response.status === 400) {
          return error.response;
        } else if (error.response.status === 403) {
          toast.error(error.response.data.Message);
          ls.clear("token");
          ls.clear("brands");
          window.location.href = "/login";
        } else {
          return error.response;
          // console.log(error)
        }
      }
    });
};

export const getListOfDateSearchCoupon = (
  guid,
  page,
  couponStartDate,
  couponStartDateTo,
  couponEndDate,
  couponEndDateTo
) => {
  console.log(page);
  let data = JSON.stringify({
    brandId: guid,
    active: 1,
    page: page ? page : 1,
    count: 10,
    sort: "-name",
    "include-clip": true,
    "include-redemption": true,
    search:"",
    "coupon-start-date": couponStartDate ? couponStartDate : "",
    "coupon-start-date-to": couponStartDateTo ? couponStartDateTo : "",
    "coupon-end-date": couponEndDate ? couponEndDate : "",
    "coupon-end-date-to":couponEndDateTo?couponEndDateTo:"",
  });

  let config = {
    method: "post",
    url: CONFIG.API_URL + "coupon/search",
    headers: CONFIG.config_head.headers,
    data: data
  };
  return axios(config)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      if (error.response) {
        if (error.response.status === 400) {
          return error.response;
        } else if (error.response.status === 403) {
          toast.error(error.response.data.Message);
          ls.clear("token");
          ls.clear("brands");
          window.location.href = "/login";
        } else {
          return error.response;
          // console.log(error)
        }
      }
    });
};

export const getMasterRoles = async () => {
  let config = {
    method: "get",
    url: CONFIG.API_URL + `master?type=role&search=''`,
    headers: CONFIG.config_head.headers
  };
  return await axios(config)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      if (error.response) {
        if (error.response.status === 400) {
          toast.error(error.response.data.Message);
        } else if (error.response.status === 403) {
          toast.error(error.response.data.Message);
          ls.clear("token");
          ls.clear("brands");
          window.location.href = "/login";
        } else {
          toast.error(error.response.data.Message);
        }
      }
    });
};

export const getBrandWholesaler = async (brnad_guid) => {
  let config = {
    method: "get",
    url: CONFIG.API_URL + `master/${brnad_guid}?type=wholesaler&search=''`,
    headers: CONFIG.config_head.headers
  };
  return await axios(config)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      if (error.response) {
        if (error.response.status === 400) {
          toast.error(error.response.data.Message);
        } else if (error.response.status === 403) {
          toast.error(error.response.data.Message);
          ls.clear("token");
          ls.clear("brands");
          window.location.href = "/login";
        } else {
          toast.error(error.response.data.Message);
        }
      }
    });
};

export const getBrand = async (guid) => {
  let config = {
    method: "get",
    url: CONFIG.API_URL + `brand/${guid}`,
    headers: CONFIG.config_head.headers
  };

  return await axios(config)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      if (error.response) {
        if (error.response.status === 400) {
          toast.error(error.response.data.Message);
        } else if (error.response.status === 403) {
          toast.error(error.response.data.Message);
          ls.clear("token");
          ls.clear("brands");
          window.location.href = "/login";
        } else {
          toast.error(error.response.data.Message);
        }
      }
    });
};

export const updateWholesaleruser = async (data, user_guid, brand_guid) => {
  var config = {
    method: "put",
    url: CONFIG.API_URL + `user/${user_guid}/${brand_guid}`,
    headers: CONFIG.config_head.headers,
    data: data
  };

  return await axios(config)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      if (error.response) {
        if (error.response.status === 400) {
          return error.response;
        } else if (error.response.status === 403) {
          toast.error(error.response.data.Message);
          ls.clear("token");
          ls.clear("brands");
          window.location.href = "/login";
        } else {
          return error.response;
          // console.log(error)
        }
      }
    });
};

export const downloadallCoupon = (wholesaler_number, brand_guid) => {
  var config = {
    method: "get",
    url:
      CONFIG.API_URL +
      `coupon/download-all?WSNo=${wholesaler_number}&BrandGuid=${brand_guid}`,
    headers: CONFIG.config_head.headers
  };

  return axios(config)
    .then((response) => {
      if (response.status === 200) {
        return response;
      }
    })
    .catch(function (error) {
      if (error.response.status === 502) {
        toast.error("Bad Request!");
      } else if (error.response.status === 400) {
        // toast.error('Bad Request!');
        return error.response;
        //toast.error(error.response.data.Message);
      } else {
        return error.response;
      }
    });
};

//list of company for bulkcoupon
export const getCompany = async () => {
  let config = {
    method: "get",
    url: CONFIG.API_URL + `/company/all`,
    headers: CONFIG.config_head.headers
  };

  return await axios(config)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      if (error.response) {
        if (error.response.status === 400) {
          toast.error(error.response.data.Message);
        } else if (error.response.status === 403) {
          toast.error(error.response.data.Message);
          ls.clear("token");
          ls.clear("brands");
          window.location.href = "/login";
        } else {
          toast.error(error.response.data.Message);
        }
      }
    });
};

//list of retailers for bulkcoupon
export const getRetailers = async () => {
  let wholesaler_number = ls.get("brands") ? ls.get("brands")[0].guid : "";
  let config = {
    method: "get",
    url: CONFIG.API_URL + `master/${wholesaler_number}?type=retailer&search=`,
    headers: CONFIG.config_head.headers
  };

  return await axios(config)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      if (error.response) {
        if (error.response.status === 400) {
          toast.error(error.response.data.Message);
        } else if (error.response.status === 403) {
          toast.error(error.response.data.Message);
          ls.clear("token");
          ls.clear("brands");
          window.location.href = "/login";
        } else {
          toast.error(error.response.data.Message);
        }
      }
    });
};

export const getWholesalers = async () => {
  let wholesaler_number = ls.get("brands") ? ls.get("brands")[0].guid : "";
  let config = {
    method: "get",
    url: CONFIG.API_URL + `master/${wholesaler_number}?type=wholesaler&search=`,
    headers: CONFIG.config_head.headers
  };

  return await axios(config)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      if (error.response) {
        if (error.response.status === 400) {
          toast.error(error.response.data.Message);
        } else if (error.response.status === 403) {
          toast.error(error.response.data.Message);
          ls.clear("token");
          ls.clear("brands");
          window.location.href = "/login";
        } else {
          toast.error(error.response.data.Message);
        }
      }
    });
};

export const getListOfRetailerGroups = () => {
  let config = {
    method: "get",
    url: CONFIG.API_URL + "user/list-retailer-groups",
    headers: CONFIG.config_head.headers,
  };
  return axios(config)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      if (error.response) {
        if (error.response.status === 400) {
          return error.response;
        } else if (error.response.status === 403) {
          toast.error(error.response.data.Message);
          ls.clear("token");
          ls.clear("brands");
          window.location.href = "/login";
        } else {
          return error.response;
          // console.log(error)
        }
      }
    });
};

export const createRetailerGroup = (data) => {
  let config = {
    method: "post",
    url: CONFIG.API_URL + "user/create-retailer-group",
    headers: CONFIG.config_head.headers,
    data: data,
  };
  return axios(config)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      if (error.response) {
        if (error.response.status === 400) {
          return error.response;
        } else if (error.response.status === 403) {
          toast.error(error.response.data.Message);
          ls.clear("token");
          ls.clear("brands");
          window.location.href = "/login";
        } else {
          return error.response;
          // console.log(error)
        }
      }
    });
};

export const getRetailerGroup = (guid) => {
  let config = {
    method: "get",
    url: CONFIG.API_URL + `user/get-retailer-group/${guid}`,
    headers: CONFIG.config_head.headers,
  };
  return axios(config)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      if (error.response) {
        if (error.response.status === 400) {
          return error.response;
        } else if (error.response.status === 403) {
          toast.error(error.response.data.Message);
          ls.clear("token");
          ls.clear("brands");
          window.location.href = "/login";
        } else {
          return error.response;
          // console.log(error)
        }
      }
    });
};

export const updateRetailerGroup = (data, guid) => {
  let config = {
    method: "put",
    url: CONFIG.API_URL + `user/update-retailer-group/${guid}`,
    headers: CONFIG.config_head.headers,
    data: data,
  };
  return axios(config)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      if (error.response) {
        if (error.response.status === 400) {
          return error.response;
        } else if (error.response.status === 403) {
          toast.error(error.response.data.Message);
          ls.clear("token");
          ls.clear("brands");
          window.location.href = "/login";
        } else {
          return error.response;
          // console.log(error)
        }
      }
    });
};  

export const deleteRetailerGroup = (guid) => {
  let config = {
    method: "delete",
    url: CONFIG.API_URL + `user/delete-retailer-group/${guid}`,
    headers: CONFIG.config_head.headers,
  };
  return axios(config)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      if (error.response) {
        if (error.response.status === 400) {
          return error.response;
        } else if (error.response.status === 403) {
          toast.error(error.response.data.Message);
          ls.clear("token");
          ls.clear("brands");
          window.location.href = "/login";
        } else {
          return error.response;
          // console.log(error)
        }
      }
    });
};