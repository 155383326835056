import React, { useEffect, useState } from "react";
import Header from "../../Components/Wholesaler/layout/header";
import WhHeader from "../../Components/Admin/layout/header";
import ls from "local-storage";
import { getBrand, getBrandWholesaler, updateWholesaleruser } from "../../API/Wholesalerapi";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const RetailerGroups = () => {

    const [users, SetUser] = useState('');
    const [loading, setLoading] = useState(false);
    const brnad_guid = ls.get('brands') ? ls.get('brands')[0].guid : '';
    const brands = ls.get('brands') ? ls.get('brands') : '';

    const showLoader = () => {
        setLoading(true);
    }
    const hideLoader = () => {
        setLoading(false);
    };
    
    const Loader = () => <div id="cover-spin"></div>;
    return (
        <>
            {
                brands[0].scopes.includes('all') ?

                    <WhHeader location={`adminProfile`} />
                    :
                    <Header location={`companyProfile`} />
            }
            {loading ? <Loader /> : null}

            <div>Retailer-Groups</div>
        </>
    )
}

export default RetailerGroups;