import React from "react";
import { useEffect, useState } from "react";
import { getRetailers } from "../../API/Wholesalerapi";
import { getWholesalers } from "../../API/Wholesalerapi";

function SelectTarget({
  changeTargetTab,
  changePrevTab,
  wholesaler,
  selectWholesaler,
  retailer,
  selectRetailer,
  selectedOption,
  handleOptionChange,
  selectAll,
  select_all,
  selectAllRetailers,
  changeTargetTabSelect
}) {
  const [retailersData, setRetailersData] = useState();
  const [wholesalersData, setWholesalersData] = useState();

  //const [selectedOption, setSelectedOption] = useState("");

  const handleCheckboxChange = (event, option) => {
    handleOptionChange(option);
  };

  const getRetailersName = async () => {
    const responseData = await getRetailers();
    responseData?.data?.Message?.length > 0
      ? setRetailersData(responseData.data.Message)
      : setRetailersData([]);
  };
  const getWholesalerName = async () => {
    const responseData = await getWholesalers();
    responseData?.data?.Message?.length > 0
      ? setWholesalersData(responseData.data.Message?.filter((el) => el.has_full_access))
      : setWholesalersData([]);
  };
  useEffect(() => {
    getRetailersName();
    getWholesalerName();
  }, []);
  // console.log(wholesalersData)
  return (
    <div className="row  g-3">
      <div className=" col-12">
        <div className="Common_Block">
          <div className="Common_Block_Header d-flex align-items-center bg-white text-black justify-content-center">
            <h5 className="text-black mr-10">
              <input
                type="checkbox"
                className="mr-7"
                id="wholesalercheckbox"
                checked={selectedOption === "wholesaler"}
                onChange={(e) => handleCheckboxChange(e, "wholesaler")}
              />
              Wholesalers
            </h5>
            <h5 className="text-black">
              <input
                type="checkbox"
                className="mr-7"
                id="retailercheckbox"
                checked={selectedOption === "retailer"}
                onChange={(e) => handleCheckboxChange(e, "retailer")}
              />
              Retailers
            </h5>
          </div>
          <div className="Common_Block_Body pr-12 pl-12 pt-20 pb-20">
            {
              selectedOption === "retailer" ?
              <div
              className="header_right"
              style={{ float: "right", marginTop: "-8px", color: "#000" }}
            >
              <input
                type="checkbox"
                style={{ marginRight: "5px", width: "18px", height: "18px" }}
                onChange={(e) => selectAllRetailers(retailersData)}
                value={select_all}
              />
              Check / Uncheck All
            </div>
            :
            ""
            }
            <ul className="mt-30 d-flex Retailer">
              {selectedOption === "retailer" &&
                retailersData.map((eachData) => (
                  <li>
                    
                    <div
                      className="Retailer_Block"
                      style={{ boxShadow: "3px 3px 3px #B2BEB5" }}
                    >
                      <input
                        type="checkbox"
                        id="Retailer"
                        checked={retailer.some(
                          (el) => el.retailer_id === eachData.retailer_id
                        )}
                        onChange={(e) =>
                          selectRetailer(
                            e,
                            eachData.retailer_name,
                            eachData.retailer_id,
                            eachData.wholesaler_id,
                            eachData.logo
                          )
                        }
                      />
                      <div className="RetailerImage">
                        <img src={eachData.logo} className="img-fluid " />
                      </div>
                      <h5>{eachData.retailer_name}</h5>
                    </div>
                  </li>
                ))}
            </ul>

            {
              selectedOption === "wholesaler" ?
              <div
              className="header_right"
              style={{ float: "right", marginTop: "-8px", color: "#000" }}
            >
              <input
                type="checkbox"
                style={{ marginRight: "5px", width: "18px", height: "18px" }}
                onChange={(e) => { selectAll(wholesalersData) }}
                value={select_all}    
              />
              Check / Uncheck All
            </div>
            :
            ""
            }
            <ul className="mt-30 d-flex Retailer">
              {selectedOption === "wholesaler" &&
                wholesalersData.map((eachData) => (
                  <li>
                    <div
                      className="Retailer_Block"
                      style={{ boxShadow: "3px 3px 3px #B2BEB5" }}
                    >
                      <input
                        type="checkbox"
                        id="Retailer"
                        checked={wholesaler.some(
                          (el) => el.wholesaler_id === eachData.wholesaler_id
                        )}
                        onChange={(e) =>
                          selectWholesaler(
                            e,
                            eachData.logo,
                            eachData.wholesaler_id,
                            eachData.wholesaler_guid
                          )
                        }
                      />
                      <div className="RetailerImage">
                        <img src={eachData.logo} className="img-fluid " />
                      </div>
                      <h5>{eachData.wholesaler_name}</h5>
                    </div>
                  </li>
                ))}
            </ul>
            <div className="row mt-10">
              <div className="col-12 d-flex justify-content-end">
                <div className="TableButton">
                  <button
                    type="button"
                    className="mr-10 btn btn-primary"
                    onClick={(e) => {
                      changePrevTab(e, 2);
                    }}
                  >
                    <i
                      className="fas fa-arrow-left mr-10"
                      aria-hidden="true"
                    ></i>{" "}
                    Prev
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={(e) => {
                      changeTargetTabSelect(e, 4);
                    }}
                  >
                    Next{" "}
                    <i
                      className="fas fa-arrow-right ml-10"
                      aria-hidden="true"
                    ></i>{" "}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SelectTarget;
