import React, { useEffect, useState } from "react";
import { BrowserRouter, Switch, Route, Redirect, useHistory } from "react-router-dom";
import LogIn from "./Pages/LogIn";
import SignUp from "./Pages/SignUp";
import Dashboard from "./Pages/Dashboard";
import WizardBulk from "./Pages/Dashboard/BulkCouponWizard/Wizard";
import Wizard from "./Pages/Wizard"
import CouponConfirmationMessage from "./Pages/Dashboard/BulkCouponWizard/CouponConfirmationMessage";
import UpcList from "./Pages/UpcList";

import UpcDetails from "./Pages/UpcList/UpcDetails";

import Coupons from "./Pages/Coupons";
import CouponsDetails from "./Pages/Coupons/CouponsDetails";
import ConfirmationMessage from "./Components/ConfirmationMessage";
import Confirmdealmessage from "./Components/ConfirmationMessage/confirmdealMessage";

import EditCoupon from "./Pages/Wizard/EditCoupon";
import Profile from "./Pages/Profile";
import BrandList from "./Pages/BrandList";
import HelpCenter from "./Pages/HelpCenter";
import HelpCenterDetail from "./Pages/HelpCenter/HelpCenterDetail";
import BrandDetails from "./Pages/BrandList/BrandDetails";
import ResetPassword from "./Pages/ResetPassword";
import Notification from "./Pages/Notification";
import AddUser from "./Pages/AddUser";
import Categories from "./Pages/Caegories";
import Editcategory from "./Pages/Caegories/Editcategory";
import Auth from "./auth";
import NewToken from "./Pages/LogIn/NewToken";
import ls from "local-storage";
import { useIdleTimer } from 'react-idle-timer'
import UserInactive from "./Pages/LogIn/UserInactive";
import Deal from "./Pages/Deal";
import Listdeal from "./Pages/Listdeal";
import Editdeal from "./Pages/Deal/Editdeal";
import Orderdeal from "./Pages/Listdeal/Order";
import Helpcenter from "./Pages/HelpCenter/Helpcentre";
import CompanyProfile from "./Pages/CompanyProfile";
import CompanyUpc from "./Pages/CompanyUPCs";
import List from "./Pages/Wholesaler/List";
import WholesalerCoupon from "./Pages/WholesalerCoupon";
import EditWholesalerCoupon from "./Pages/WholesalerCoupon/EditCoupon";
import CopyWholesalerCoupon from "./Pages/WholesalerCoupon/CopyCoupon";

import AddWholesaler from "./Pages/AddWholesaler/AddWholesaler";
import WholesalerProfile from "./Pages/WholesalerProfile";
import WholesalecouponDetails from "./Pages/CouponDetails/CouponsDetails";
import Header from "./Components/Wholesaler/layout/header";
import RetailerGroups from "./Pages/RetailerGroups";
const auth = new Auth();
// wholesaler and broker role related changes deployed
const MainLayoutRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(matchProps) =>
        auth.isAuthenticated() ?
          <Component {...matchProps} />
          :
          (
            ls.get("token") ?
              <NewToken />
              :
              <Redirect to="/login" />
          )
      }
    />
  )
}

function App() {
  const timeout = 600000
  const [remaining, setRemaining] = useState(timeout)
  const [isIdle, setIsIdle] = useState(false)

  useEffect(() => {
    setRemaining(getRemainingTime())

    setInterval(() => {
      setRemaining(getRemainingTime())
    }, 1000)
  }, [])

  const handleOnActive = () => {
    remaining > 0 && setIsIdle(false)
  }
  const handleOnIdle = () => {
    const paths = ["/login", "/", "/SignUp", '?userInactive'];
    if (paths.includes(window.location.pathname) || paths.includes(window.location.search)) {
      setRemaining(timeout);
      setIsIdle(false)
    }
    else {
      setIsIdle(true)
      setRemaining(0)
    }

  }

  const {
    getRemainingTime
  } = useIdleTimer({
    timeout,
    onActive: handleOnActive,
    onIdle: handleOnIdle
  })

  const path = window.location.pathname;
  const locations = [
    "/create-bulkcoupon"
  ];
  return (
    <React.Fragment>
      {
        isIdle ? <UserInactive />
          :
          
          <BrowserRouter>
             {locations.includes(path) && <Header />}
            <Switch>
              <Route exact path="/" component={LogIn} />
              <Route path="/login" component={LogIn} />
              <Route path="/SignUp" component={SignUp} />
              <Route path="/forgotpassword" component={ResetPassword} />

              <Route path="/helpcentre" component={Helpcenter} />

              <MainLayoutRoute path="/dashboard" component={Dashboard} />
              <MainLayoutRoute path="/Wizard/:slug" component={Wizard} />
              <MainLayoutRoute path="/upclist" component={UpcList} />
              <MainLayoutRoute path="/coupons" component={Coupons} />
              <MainLayoutRoute path="/confirmation/:slug" component={ConfirmationMessage} />
              <MainLayoutRoute path="/CouponsDetails/:coupon_guid" component={CouponsDetails} />
              <MainLayoutRoute path="/coupon/:coupon_guid" component={EditCoupon} />
              <MainLayoutRoute path="/profile/:user_guid" component={Profile} />
              <MainLayoutRoute path="/brandlist" component={BrandList} />
              <MainLayoutRoute path="/brandetails/:b_guid" component={BrandDetails} />
              <MainLayoutRoute path="/helpcenter" component={HelpCenter} />
              <MainLayoutRoute path="/helpcenterdetails" component={HelpCenterDetail} />

              <MainLayoutRoute path="/Notification" component={Notification} />
              <MainLayoutRoute path="/upcdetails" component={UpcDetails} />
              <MainLayoutRoute path="/AddUser" component={AddUser} />
              <MainLayoutRoute path="/categories" component={Categories} />
              <MainLayoutRoute path="/editcategory" component={Editcategory} />
              <MainLayoutRoute path="/deal" component={Deal} />
              <MainLayoutRoute path="/listdeal" component={Listdeal} />

              <MainLayoutRoute path="/confirmdealmessage" component={Confirmdealmessage} />
              <MainLayoutRoute path="/editdeal" component={Editdeal} />
              <MainLayoutRoute path="/orderdeal" component={Orderdeal} />
              <MainLayoutRoute path="/companyprofile/:user_guid" component={CompanyProfile} />
              <MainLayoutRoute path="/companyupc/" component={CompanyUpc} />
              <MainLayoutRoute path="/wholesalerlist/" component={List} />

              <MainLayoutRoute path="/create-wholesalercoupon/" component={WholesalerCoupon} />
              <MainLayoutRoute path="/edit-whcoupon/:coupon_guid" component={EditWholesalerCoupon} />
              <MainLayoutRoute path="/copy-whcoupon/:coupon_guid" component={CopyWholesalerCoupon} />

              <MainLayoutRoute path="/create-wholesaler" component={AddWholesaler} />
              <MainLayoutRoute path="/wholesaler-profile/:user_guid" component={WholesalerProfile} />
              <MainLayoutRoute path="/whcoupon-details/:coupon_guid" component={WholesalecouponDetails} />
              <MainLayoutRoute path="/admin-profile/:user_guid" component={WholesalerProfile} />
              
              <MainLayoutRoute path="/create-bulkcoupon" component={WizardBulk} />
              <MainLayoutRoute path="/coupon-confirmation/:slug" component={CouponConfirmationMessage} />

              <MainLayoutRoute path="/retailer-groups" component={RetailerGroups} />
            </Switch>
          </BrowserRouter>
      }
    </React.Fragment>
  );
}

export default App;
