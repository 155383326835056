import React from "react";
import Logo from "../../Assets/images/logo.png";
import ProductMoment from "../../Assets/images/product.svg";
import BuyOnline from "../../Assets/images/buy-online.svg";
import Digitalcoupons from "../../Assets/images/digital-coupons.svg";
import Insights from "../../Assets/images/insights.svg";
import NearBy from "../../Assets/images/nearby.svg";

import Arrow from "../../Assets/images/arrow.svg";
import { Button, Form, Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";

import * as CONFIG from "../../Components/_config";
import axios from "axios";
import ls from "local-storage";
import RV from "simple-react-validator";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import jwt_decode from "jwt-decode";
import $ from "jquery";
import TermsCondition from "../TermsCondition";

class LogIn extends React.Component {
  constructor(props) {
    super(props);
    this.validator = new RV();
    this.validator.visibleFields = [];
    toast.configure();
    this.state = {
      res: "",
      formControls: {
        username: "",
        password: ""
      },
      showError: false,
      loginLoader: false
    };

    this.acceptTerm = this.acceptTerm.bind(this);
    this.getBrands = this.getBrands.bind(this);
    this.getCompany = this.getCompany.bind(this);
  }

  componentDidMount() {
    if (window.location && window.location.search && window.location.search === "?userInactive") {
      this.setState({
        showError: true
      })
    }
    if (ls.get("decodetoken")) {
      if (ls.get("decodetoken").is_consent_provided === false) {
        $("#myModal").trigger("click");
      }
    }
  }

  changeHandler = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    this.setState({
      formControls: {
        ...this.state.formControls,
        [name]: value
      }
    });
  };

  getCompany = async (token,expiresAt) => {
    const hostname = CONFIG.getHostName(window.location.href) || "";
    var headers;
    if (window.location.hostname === "localhost" || hostname === "dev.dcbzsxgtdyea0.amplifyapp.com") {
      headers = {
        "Content-Type": "application/json",
        Authorization: token,
        "x-api-key": "KNeEYFr1sCaTbAuDYhBi33UXmf3gzMtm7Cv0k95O"
      }
    }
    else {
      headers = {
        "Content-Type": "application/json",
        Authorization: token,
      }
    }
    let config = {
      method: "get",
      url: CONFIG.API_URL + `/company/all`,
      headers: headers
    };
    await axios(config)
    .then((response) => {
      //console.log("company data broker",response.data.Message)
      ls.set("companyDataBroker", response.data.Message);
      this.getBrands(token, expiresAt)
      //window.location.href = "/dashboard"
    })
    .catch((error) => {
      this.setState({
        loginLoader: false
      }, () => {
        toast.error(error && error.response && error.response.data.Message ? error.response.data.Message : "Internal Server Error");
      })
    });
  }


  getBrands = async (token, expiresAt) => {
    const hostname = CONFIG.getHostName(window.location.href) || "";
    var headers;
    if (window.location.hostname === "localhost" || hostname === "dev.dcbzsxgtdyea0.amplifyapp.com") {
      headers = {
        "Content-Type": "application/json",
        Authorization: token,
        "x-api-key": "KNeEYFr1sCaTbAuDYhBi33UXmf3gzMtm7Cv0k95O"
      }
    }
    else {
      headers = {
        "Content-Type": "application/json",
        Authorization: token,
      }
    }
    var config = {
      method: "get",
      url: CONFIG.API_URL + "user/authorizations/",
      headers: headers
    };
    await axios(config)
      .then((response) => {
        this.setState({
          loginLoader: false
        }, () => {



          let data = response.data.Message.brands;
          var decoded = jwt_decode(token);


          if (data[0].scopes[0] === "all") {
            ls.remove("token");
            ls.remove("username");
            ls.remove("password");
            ls.remove("brands");
            ls.set("token", token);
            ls.set("brands", data);
            ls.set("decodetoken", decoded);
            ls.set("expiresAt", expiresAt);
            toast.success("Login Succesful", { theme: "colored" });
            window.location.href = "/brandlist"
          }
          else if (data[0].scopes.includes('wholesaler:user')) {
            ls.remove("token");
            ls.remove("username");
            ls.remove("password");
            ls.remove("brands");
            ls.set("token", token);
            ls.set("brands", data);
            ls.set("decodetoken", decoded);
            ls.set("expiresAt", expiresAt);
            toast.success("Login Succesful", { theme: "colored" });
            window.location.href = "/wholesalerlist"
          }
          else if (decoded.scopes.includes('broker:user')) {
            if (decoded.is_consent_provided === false) {
              $("#myModal").trigger("click");
              ls.set("token", token);
              ls.set("decodetoken", decoded);
              ls.set("brands", data);
              ls.set("expiresAt", expiresAt)
              return false;
            } else {
              ls.remove("token");
              ls.remove("brands");
              ls.remove("username");
              ls.remove("password");
              ls.set("token", token);
              ls.set("decodetoken", decoded);
              ls.set("brands", data);
              ls.set("expiresAt", expiresAt);
              ls.set("user","broker")
              if (data.length === 1) {

                ls.set('selectedbrand', data)
              }

              toast.success("Login Succesful", {
                theme: "colored"
              });

              window.location.href = "/dashboard"
            }
          }
          else {

            if (decoded.is_consent_provided === false) {
              $("#myModal").trigger("click");
              ls.set("token", token);
              ls.set("decodetoken", decoded);
              ls.set("brands", data);
              ls.set("expiresAt", expiresAt)
              return false;
            } else {
              ls.remove("token");
              ls.remove("brands");
              ls.remove("username");
              ls.remove("password");
              ls.set("token", token);
              ls.set("decodetoken", decoded);
              ls.set("brands", data);
              ls.set("expiresAt", expiresAt);
              ls.set("user","brands")
              if (data.length === 1) {

                ls.set('selectedbrand', data)
              }

              toast.success("Login Succesful", {
                theme: "colored"
              });
              window.location.href = "/dashboard"
            }
          }
        })
      })
      .catch((error) => {
        this.setState({
          loginLoader: false
        }, () => {
          toast.error(error && error.response && error.response.data.Message ? error.response.data.Message : "Internal Server Error");
        })
      });
  }

  Login = async (e) => {

    e.preventDefault();
    if (!this.validator.allValid()) {
      toast.error("Please fill all the fields.");
      this.forceUpdate();
      return false;
    }

    var data = new FormData();
    var obj = {};
    let myHeaders = new Headers(obj);
    myHeaders = CONFIG.config_head.headers;
    myHeaders.username = this.state.formControls.username;
    myHeaders.password = this.state.formControls.password.trim();

    this.setState({
      loginLoader: true
    }, () => {
      var config = {
        method: "post",
        url: CONFIG.API_URL + "login/token",
        headers: myHeaders,
        data: data
      };
      axios(config)
        .then((res) => {
          if (res.status === 200) {
            let expiresAt = JSON.stringify(
              res.data.Message.expires_in * 1000 + new Date().getTime()
            )
            let decodedToken = jwt_decode(res.data.Message.access_token);
            if (decodedToken.scopes.includes('broker:user')) {
              this.getCompany(res.data.Message.access_token,expiresAt)
            }
            else {
              this.getBrands(res.data.Message.access_token, expiresAt)
            }
          }
          else {
            this.setState({
              loginLoader: false
            }, () => {
              toast.error(res.data.Message);
            })
          }
        })
        .catch(error => {
          this.setState({
            loginLoader: false
          }, () => {
            toast.error(error.response.data.Message);
          })
        });
    })
  };

  async acceptTerm() {
    var token = ls.get("token") ? ls.get("token") : "";

    var config = {
      method: "post",
      url: CONFIG.API_URL + "user/consent",
      headers: {
        "Content-Type": "application/json",
        Authorization: token
      }
    };
    axios(config)
      .then((response) => {

        if (response.status === 201) {
          var username = this.state.formControls.username;
          var password = this.state.formControls.password;
          var data = new FormData();
          var config = {
            method: "post",
            url: CONFIG.API_URL + "login/token",
            headers: {
              "Content-Type": "application/json",
              username: username,
              password: password
            },
            data: data
          };
          axios(config).then((res) => {
            if (res.status === 200) {

              var decoded = jwt_decode(res.data.Message.access_token);
              let expiresAt = JSON.stringify(
                res.data.Message.expires_in * 1000 + new Date().getTime()
              )
              ls.remove("token");
              ls.remove("brands");
              ls.remove("username");
              ls.remove("password");
              ls.set("token", res.data.Message.access_token);
              ls.set("decodetoken", decoded);
              this.getBrands(res.data.Message.access_token, expiresAt)
            }
          });
        }
      })
      .catch((error) => {
        if (error.response) {
          toast.error(error.response.data.Message);
        }
      });
  }



  render() {
    return (
      <div className="FormBlock">
        <TermsCondition simplifiedFunction={this.acceptTerm} />
        <div className="container-fluid ">
          <div className="row">
            <div className="col-12">
              <div className="Logo pt-21 pb-21">
                <img src={Logo} className="img-fluid" alt="Logo" />
              </div>
            </div>
          </div>
        </div>
        <div className="container ">
          <div className=" MainBlock pb-20">
            <div className="row  align-items-center justify-content-between">
              <div className="col-xl-4 col-lg-5 order-lg-1 order-2 pr-100">
                <div className="LeftBlock">
                  <h2 className="">Fueled by Real Time Trusted Data</h2>
                  <h4 className="mt-10">
                    Drive Velocity Digitally with ML & AI{" "}
                  </h4>
                  <ul className="Data_Listing mt-63">
                    <li>
                      <img
                        src={ProductMoment}
                        className="img-fluid"
                        alt="Product"
                      />
                      <span>Product Movement</span>
                    </li>
                    <li>
                      <img
                        src={BuyOnline}
                        className="img-fluid"
                        alt="Product"
                      />
                      <span>Buy Online</span>
                    </li>
                    <li>
                      <img
                        src={Digitalcoupons}
                        className="img-fluid"
                        alt="Product"
                      />
                      <span>Digital Coupons</span>
                    </li>
                    <li>
                      <img src={Insights} className="img-fluid" alt="Product" />
                      <span>Insights</span>
                    </li>
                    <li>
                      <img src={NearBy} className="img-fluid" alt="Product" />
                      <span>Nearby.ai</span>
                    </li>
                  </ul>
                  <ul className="CommonLink mt-85 d-flex align-items-center">
                    <li>
                      <a href="https://rsaamerica.com/terms/" target={"blank"}>
                        Terms
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://rsaamerica.com/privacy-policy/"
                        target={"blank"}
                      >
                        Privacy
                      </a>
                    </li>
                    <li>
                      <a href="/">Home</a>
                    </li>
                    {/* <li>
                      <a href="/helpcentre">Support Center</a>
                    </li> */}
                  </ul>
                </div>
              </div>
              <div className="col-xl-5 col-lg-6 col-12 order-lg-2 order-1 pl-140">
                <div className="Form_OuterBlock pt-54 pb-59  ">
                  <div className="Form_Header">
                    <h5>
                    Welcome To <span>BrandIQ</span><sub>ai</sub>
                    </h5>
                    <h2>Sign In</h2>
                  </div>

                  <form method="post" className="mt-69">
                    <div className="InputField CustomScroll LogInField">
                      <Form.Group
                        className="form-group"
                        controlId="formBasicEmail"
                      >
                        <Form.Label>Enter Your Email Address</Form.Label>
                        <Form.Control
                          type="email"
                          placeholder="Enter Email*"
                          name="username"
                          onChange={this.changeHandler}
                          value={this.state.formControls.username}
                          required=""
                        />

                        <samp className="text-danger">
                          {this.validator.message(
                            "Email",
                            this.state.formControls.username,
                            `required|email`
                          )}
                        </samp>
                      </Form.Group>
                      <Form.Group
                        className="mt-31 form-group"
                        controlId="formBasicPassword"
                      >
                        <Form.Label>Enter Your Password</Form.Label>
                        <Form.Control
                          type="password"
                          placeholder="Enter Password*"
                          name="password"
                          onChange={this.changeHandler}
                          required=""
                        />
                        {/* <img src={Eye} className='img-fluid InputIcon' alt="eye" /> */}
                        <samp className="text-danger">
                          {this.validator.message(
                            "Passoword",
                            this.state.formControls.password,
                            `required`
                          )}
                        </samp>
                      </Form.Group>
                    </div>
                    <div className="pl-34 pr-34 Button_Outer_Block">
                      <Button
                        variant=""
                        type="submit"
                        className="mt-48 Btn_blue"
                        disabled={this.state.loginLoader}
                        onClick={(e) => {
                          this.Login(e);
                        }}
                      >
                        Login
                        {
                          this.state.loginLoader ?

                            <Spinner
                              as="span"
                              animation="border"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                              className={"float-end"}
                            />
                            :
                            <img
                              src={Arrow}
                              className="img-fluid float-end"
                              alt="Arrow"
                            />
                        }
                      </Button>
                    </div>
                    {
                      this.state.showError &&
                      <div className="pl-34 pr-34 Button_Outer_Block login-page-error-message">
                        We have signed you out due to inactivity.
                      </div>
                    }
                  </form>
                  <div className="Form_Footer">
                    <button
                      id="myModal"
                      data-bs-toggle="modal"
                      data-bs-target="#exampleModal"
                      style={{ display: "none" }}
                    >
                      Modal
                    </button>

                    <Link to="/forgotpassword" className="Link mt-42">
                      Forgot Password?
                    </Link>
                    <span className="RegisterLink mt-62">
                      Are you new? <Link to="/SignUp">Register Here</Link>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default LogIn;
